<script setup lang="ts">
import type { User } from '@/types/api'

const authStore = useAuthStore()

const store = useAppStore()
const activeDropdown = ref('')
const user = ref<Partial<User>>({})

function toggleMobileMenu() {
  if (window.innerWidth < 1024) {
    store.toggleSidebar()
  }
}

const menuItemsSetup = ref([
  {
    id: 1,
    title: 'TypesPlaces',
    icon: 'hugeicons:store-01',
    link: '/setup/types',
    admin: true,
  },
  {
    id: 2,
    title: 'Cities',
    icon: 'hugeicons:city-02',
    link: '/setup/cities',
    admin: true,
  },
  {
    id: 3,
    title: 'payments',
    icon: 'hugeicons:credit-card',
    link: '/setup/payments',
    admin: true,
  },
  {
    id: 4,
    title: 'users',
    icon: 'hugeicons:user',
    link: '/setup/users',
    admin: true,
  },
  {
    id: 5,
    title: 'userRoles',
    icon: 'hugeicons:share-knowledge',
    link: '/setup/roles',
    admin: true,
  },
  {
    id: 5,
    title: 'adminRoles',
    icon: 'hugeicons:microsoft-admin',
    link: '/setup/roles-admin',
    admin: true,
  },
  {
    id: 6,
    title: 'TypeDocuments',
    icon: 'hugeicons:license-third-party',
    link: '/setup/documents',
    admin: true,
  },
  {
    id: 7,
    title: 'Taxes',
    icon: 'hugeicons:taxes',
    link: '/setup/taxes',
    admin: true,
  },
  {
    id: 8,
    title: 'viewModules',
    icon: 'hugeicons:menu-square',
    link: '/modules',
    admin: true,
  },
  {
    id: 9,
    title: 'orders',
    icon: 'hugeicons:creative-market',
    link: '/orders',
    admin: true,
  },
  {
    id: 10,
    title: 'places',
    icon: 'hugeicons:store-04',
    link: '/places',
    admin: false,
  },
  {
    id: 11,
    title: 'travels',
    icon: 'hugeicons:motorbike-02',
    link: '/runners',
    admin: true,
  },
  {
    id: 12,
    title: 'accounts',
    icon: 'hugeicons:payment-success-01',
    link: '/accounts',
    admin: true,
  },
  {
    id: 13,
    title: 'reports',
    icon: 'hugeicons:repository',
    link: '/reports',
    admin: true,
  },
])

onMounted(async () => {
  await authStore.isAuthenticated()
  user.value = authStore.user
  if (!user.value.roleAdmin && !user.value.id_roleAdmin) {
    const newMenu = menuItemsSetup.value.filter(menu => !menu.admin)
    menuItemsSetup.value = newMenu
  }

  setTimeout(() => {
    const selector = document.querySelector(`.sidebar ul a[href="${window.location.pathname}"]`)

    if (selector) {
      selector.classList.add('active')
      const ul = selector.closest('ul.sub-menu')
      if (ul) {
        const ele = ul.closest('li.menu')!.querySelectorAll('.nav-link') || []

        if (ele.length) {
          const firstElement = ele[0] as HTMLButtonElement

          setTimeout(() => {
            firstElement.click()
          })
        }
      }
    }
  })
})
</script>

<template>
  <div :class="{ 'dark text-white-dark': store.semidark }">
    <nav class="sidebar fixed bottom-0 top-0 z-40 h-full min-h-screen w-[260px] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] transition-all duration-300">
      <div class="h-full bg-white dark:bg-[#0e1726]">
        <div class="flex items-center justify-between px-4 py-3">
          <NuxtLink
            to="/"
            class="main-logo flex shrink-0 items-center"
          >
            <NuxtImg
              class="ml-[5px] w-8 flex-none"
              src="/assets/img/logo-dark.png"
              alt=""
            />
            <span class="align-middle text-4xl font-semibold ltr:ml-1.5 rtl:mr-1.5 dark:text-white-light lg:inline">EFICI</span>
          </NuxtLink>
          <button
            class="collapse-icon flex h-8 w-8 items-center rounded-full transition duration-300 hover:bg-gray-500/10 hover:text-primary rtl:rotate-180 dark:text-white-light dark:hover:bg-dark-light/10"
            @click="store.toggleSidebar()"
          >
            <Icon
              name="hugeicons:arrow-down-double"
              class="m-auto rotate-90"
            />
          </button>
        </div>

        <PerfectScrollbar
          :options="{
            swipeEasing: true,
            wheelPropagation: false,
          }"
          class="relative h-[calc(100vh-80px)]"
        >
          <ul class="relative space-y-0.5 p-4 py-0 font-semibold">
            <li v-if="user.roleAdmin || user.id_roleAdmin" class="menu nav-item">
              <button
                type="button"
                class="nav-link group w-full"
                :class="{ active: activeDropdown === 'dashboard' }"
                @click="activeDropdown === 'dashboard' ? (activeDropdown = '') : (activeDropdown = 'dashboard')"
              >
                <div class="flex items-center">
                  <Icon
                    name="hugeicons:home-04"
                    class="shrink-0 group-hover:!text-primary"
                  />
                  <span class="text-black ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">
                    {{ $t('dashboard') }}
                  </span>
                </div>
                <div :class="{ '-rotate-90 rtl:rotate-90': activeDropdown !== 'dashboard' }">
                  <Icon
                    name="hugeicons:arrow-down-01"
                  />
                </div>
              </button>
              <div v-if="activeDropdown === 'dashboard'">
                <ul class="sub-menu text-gray-500">
                  <li>
                    <NuxtLink
                      to="/"
                      @click="toggleMobileMenu"
                    >
                      {{ $t('sales') }}
                    </NuxtLink>
                  </li>
                  <li>
                    <NuxtLink
                      to="/analytics"
                      @click="toggleMobileMenu"
                    >
                      {{ $t('analytics') }}
                    </NuxtLink>
                  </li>
                  <li>
                    <NuxtLink
                      to="/finance"
                      @click="toggleMobileMenu"
                    >
                      {{ $t('finance') }}
                    </NuxtLink>
                  </li>
                </ul>
              </div>
            </li>

            <h2 class="-mx-4 mb-1 flex items-center bg-white-light/30 px-7 py-3 font-extrabold uppercase dark:bg-dark dark:bg-opacity-[0.08]">
              <span>{{ $t('setup') }}</span>
            </h2>

            <li class="nav-item">
              <ul>
                <li
                  v-for="(menuItem) in menuItemsSetup"
                  :key="menuItem.id"
                  class="nav-item"
                >
                  <NuxtLink
                    :to="menuItem.link"
                    class="group"
                    @click="toggleMobileMenu"
                  >
                    <div class="flex items-center">
                      <Icon
                        :name="menuItem.icon"
                        class="shrink-0"
                      />

                      <span class="text-black ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">
                        {{ $t(menuItem.title) }}
                      </span>
                    </div>
                  </NuxtLink>
                </li>
              </ul>
            </li>
          </ul>
        </PerfectScrollbar>
      </div>
    </nav>
  </div>
</template>
